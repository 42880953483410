.App {
  margin: 25px;
}

.Bio {
  font-size: 13px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
